<template>
  <div class="navigation-container">
    <div class="logo-section">      
      <img :src="logoUrl" class="logo" />
      <h1 v-if="false" class="page-title">{{ $t("page-title") }}</h1>
      <h2 v-if="false" class="page-tagline">{{ $t("page-tagline") }}</h2>
    </div>
    <ion-list class="navigation">
      <navigation-item :icon="homeOutline" :label="$t('nav-dashboard-label')" route="dashboard" v-if="isOwner || permissions.includes('statistics')"></navigation-item>

      <navigation-item :icon="homeOutline" v-if="isOwner || permissions.includes('orders')" :label="$t('nav-orders-label')" route="orders" :badge="numberOfNewOrders">
        <navigation-item :icon="homeOutline" :label="$t('nav-orders-overview-label')"
          v-if="isOwner || permissions.includes('orders')"
          route="orders-overview"></navigation-item>
        <navigation-item :icon="listCircleOutline" v-show="loggedInUser" :label="$t('nav-orders-list-label')"
          v-if="isOwner || permissions.includes('orders')"
          route="orders-list">
        </navigation-item>
      </navigation-item>

      
      <navigation-item  v-if="isOwner || permissions.includes('products')" :icon="restaurantOutline" v-show="loggedInUser" :label="$t('nav-menu-label')" route="menu">
        <navigation-item v-show="loggedInUser" :label="$t('nav-products-label')"
          v-if="isOwner || permissions.includes('products')"
          route="menu-products"></navigation-item>


        <navigation-item v-show="loggedInUser" :label="$t('nav-categories-tags-courses-label')"
          v-if="isOwner || permissions.includes('products')"
          route="menu-categories-tags-courses"></navigation-item>

        <navigation-item v-if="isOwner || permissions.includes('products')"   :label="$t('nav-discounts-label')"
          route="discounts"></navigation-item>        
      </navigation-item>
      
       
      <navigation-item  :icon="qrCodeOutline"  :label="$t('nav-accounts-label')"
          route="accounts" v-if="['4hAbBagyGifa6Wd6gez0UKQ5XTE3','hDA1p2mevUSknPQ47vt5Vok5L322','VynXjRSfyjRjpMLqkWyMLinUCs03', 'I2DWMHCsSJWhJE10xLyxpYxaOSM2','R7CWx3VNyXNOyEXSobDN9MMkpQi2','hcobxyPAUFMWztGJERpDrCd1EJL2', 'UfDvkVbO6SSFp40GTCxw9RSm5EB2' ].includes(companyId) && ( isOwner || permissions.includes('accounts'))" ></navigation-item>
     
      <navigation-item :icon="qrCodeOutline" v-if="isOwner || permissions.includes('zones')" v-show="loggedInUser" :label="$t('nav-zones-label')"
        route="zones"></navigation-item>

        <navigation-item :icon="cogOutline" v-if="isOwner || permissions.includes('settings')" :label="$t('nav-delivery-settings-label')"
      route="delivery"></navigation-item>
      
      
        
      <navigation-item v-if="isOwner || permissions.includes('content')" :icon="newspaperOutline" v-show="loggedInUser" :label="$t('nav-content-label')"
        route="content"></navigation-item>

      <navigation-item :icon="cogOutline" v-if="isOwner || permissions.includes('settings')" v-show="loggedInUser" :label="$t('nav-settings-label')" route="settings" >
        <navigation-item v-if="isOwner || permissions.includes('settings')" :label="$t('nav-settings-general-settings-label')"
        route="settings-general-settings"></navigation-item>
        <navigation-item v-if="isOwner || permissions.includes('settings')" :label="$t('nav-opening-hours-settings-label')"
        route="opening-hours-settings"></navigation-item>
        
        <navigation-item v-if="isOwner || permissions.includes('settings')" v-show="(config && config.environment != 'production' && config.environment != 'testumgebung' ) || ['I2DWMHCsSJWhJE10xLyxpYxaOSM2', 'BRxEyv0typR42qGx119o5CMgCmk1','hDA1p2mevUSknPQ47vt5Vok5L322' ,'VynXjRSfyjRjpMLqkWyMLinUCs03', '98I6clnklFRboCTLKhp0bH792RD3'].includes(companyId) " :label="$t('nav-frontend-settings-label')"
        route="frontend-settings"></navigation-item>
        <navigation-item v-if="isOwner || permissions.includes('settings')" :label="$t('nav-settings-printers-label')"
          route="settings-printers"></navigation-item>

        <navigation-item v-if="isOwner || permissions.includes('settings')" :label="$t('nav-settings-company-info-label')"
        route="settings-company-info"></navigation-item>


        <navigation-item v-if="isOwner || permissions.includes('settings')" :label="$t('nav-settings-banking-label')"
          route="settings-banking"></navigation-item>
          <navigation-item v-if="isOwner || permissions.includes('settings')" :label="$t('nav-settings-api-settings-label')"
          route="settings-api"></navigation-item>
          
      </navigation-item>
      
    </ion-list>

    
    <div class="bottom-navigation">      
      <language-selector-bar></language-selector-bar>
      

      <navigation-sub-account-item v-if="!isOwner" :icon="personCircleOutline" :label="''" ></navigation-sub-account-item>
      <navigation-account-item v-if="isOwner" :icon="personCircleOutline" :label="$t('nav-account-label')" route="profile">
      </navigation-account-item>
      <div class="footer">

        <div class="links">
          <span class="link-container" v-html="$t('nav-conditions-elem')"></span>
          <span class="link-container" v-html="$t('nav-privacy-elem')"></span>
          <span class="link-container" v-html="$t('nav-contact-elem')"></span>

        </div>
        <div class="version-info">          
          <span class="value" v-text="version"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { IonList } from "@ionic/vue";
import { personCircleOutline, homeOutline, restaurantOutline, qrCodeOutline, cogOutline, listCircleOutline, newspaperOutline } from "ionicons/icons";
import NavigationItem from "@/views/NavigationItem.vue";
import NavigationAccountItem from "@/views/NavigationAccountItem.vue";
import NavigationSubAccountItem from "@/views/NavigationSubAccountItem.vue";
import LanguageSelectorBar from "@/components/LanguageSelectorBar.vue";

</script>

<script>
export default {
  computed: {
    numberOfNewOrders() {
      return this.services.dashboard.numberOfNewOrders ? this.services.dashboard.numberOfNewOrders.value : 0;
    },
    companyPictureUrl() {
      return this.services.company.companyPictureUrl ? this.services.company.companyPictureUrl.value : null;
    },
    logoUrl(){
      return this.companyPictureUrl ? this.companyPictureUrl : "/assets/images/logo-red.v2.svg"
    }
  }
};
</script>
<style scoped>
.navigation-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

ion-list.navigation {
  padding-top: 1.3rem;
  flex: 1;
  /* overflow: hidden;
  overflow-y: auto; */
}

ion-list.navigation * {
  -webkit-user-select: none; /* Safari */
  user-select: none
}

.bottom-navigation {
  padding-top: 0;
  margin-top: 0.3rem;  
  padding-bottom: 0;

}

.bottom-navigation>ion-item:last-child {
  border-bottom: none;
}

.page-title {
  font-size: 1.21rem;
  font-weight: 500;
  padding: 2.5rem 0.2rem 0.2rem 1rem;
  margin: 0;
  text-align: center;
}

.page-tagline {
  font-size: 1rem;
  text-align: center;
  font-weight: 300;
  color: var(--ion-color-medium-shade);
  padding: 0.2rem 0.2rem 0.2rem 1rem;
  margin: 0;
  margin-bottom: 1.1rem;
}

.logo-section {
  padding-top: 2.5rem;
  padding-bottom: 1rem;
  text-align: center;
}

.logo-section .logo {
  width: 35%;
  height: auto;

}


.footer {
  display: flex;
  font-size: 0.7rem;
  color: #666;
  margin-top: .45rem;
  margin-bottom: .5rem;
}

.plt-ios.plt-capacitor .footer {
  margin-top: 0.75rem;
  margin-left: 0.3rem;
  margin-bottom: 1.2rem;
  margin-right: 0.3rem;
}

.footer .links {
  flex: 1;
  margin-right: 0.5rem;
  margin-left: 0.8rem;
}

.version-info {
  text-align: right;
  margin-right: 0.5rem;
}

.version-info .value {
  margin-left: 0.15rem;
}
</style>

<style>
.navigation-container .footer .links a {
  margin-right: 0.5rem;
  font-size: 0.7rem;
  color: #666;
  text-decoration: none;
}
</style>